<template>
  <div class="categories">
    <category-list />
  </div>
</template>

<script>
import CategoryList from '@/components/categories/CategoryList.vue'

export default {
  name: 'Categories',
  components: {
    CategoryList
  }
}
</script>
