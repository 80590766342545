<template>
  <div class="transaction-list">
    <h3 class="subtitle">
      {{ $t('categories.title')}}
    </h3>

    <ul class="tags" v-if="categories.length">
      <li class="tag" v-for="category in categories" :key="category._id">
        {{ category.name }}
      </li>
    </ul>

    <empty-state v-else>
      {{ $t('categories.emptyState') }}
    </empty-state>

    <call-to-button
      :link="`/${accountBook.slug}/categories/new`"
      icon="fa fa-plus"
    >
      {{ $t('categories.newTitle') }}
    </call-to-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CallToButton from '../basic/CallToButton'
import EmptyState from '../basic/EmptyState'

export default {
  name: 'category-list',
  components: { EmptyState, CallToButton },
  computed: {
    ...mapState({
      categories: state => state.categories.items,
      accountBook: state => state.accountBooks.current
    })
  },
  created () {
    this.$store.dispatch('categories/fetch')
  }
}
</script>

<style scoped></style>
